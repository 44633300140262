




































import { Component, Vue, Prop, Watch } from "vue-property-decorator";

import ParsingRuleObjectForm from "./ParsingRuleObjectForm.vue";
import { DateRuleModel } from "@/settings/models/networks-management/ParsingRuleRequestModel";
import { RuleType } from "@/settings/models/networks-management/ParsingRuleResponseModel";
import ValidUtil from "@/shared/utils/ValidUtil";

@Component({
  components: {
    ParsingRuleObjectForm,
  },
})
export default class ParsingRuleDateForm extends Vue {
  @Prop() value!: DateRuleModel;

  readonly requiredRule = [
    ValidUtil.required(this.$lang("validation.required")),
  ];
  readonly typeItemsWithoutDateType = Object.entries(RuleType)
    .filter(([key]) => key !== RuleType.DATE)
    .map(([key, value]) => ({
      text: key,
      value,
    }));

  get localValue(): DateRuleModel {
    return this.value;
  }

  set localValue(value: DateRuleModel) {
    this.$emit("input", value);
  }

  get isObjectTypeSelected(): boolean {
    return this.localValue?.subRule?.type === RuleType.OBJECT;
  }

  @Watch("localValue.subRule.type")
  private watchLocalValueSubRuleType() {
    if (this.isObjectTypeSelected) {
      this.localValue = {
        ...this.localValue,
        subRule: {
          type: RuleType.OBJECT,
          key: "",
          keyValue: "",
          node: "",
          value: this.localValue?.subRule?.value ?? "",
        },
      };
    } else {
      this.localValue = {
        ...this.localValue,
        subRule: {
          type: this.localValue.subRule?.type ?? RuleType.CONSTANT,
          value: this.localValue?.subRule?.value ?? "",
        },
      };
    }
  }
}
