


































































































































































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import ParsingRulesTable from "./ParsingRulesTable.vue";
import ParsingRuleRequestModel from "@/settings/models/networks-management/ParsingRuleRequestModel";
import NetworkIntegrationResponseModel from "@/settings/models/networks-management/NetworkIntegrationResponseModel";
import ParsingRuleResponseModel, {
  ParsingRuleResponseFormat,
} from "@/settings/models/networks-management/ParsingRuleResponseModel";
import ValidUtil from "@/shared/utils/ValidUtil";

@Component({
  components: {
    ParsingRulesTable,
  },
})
export default class ParsingRulesForm extends Vue {
  @Prop() networkIntegration!: NetworkIntegrationResponseModel;

  readonly requiredRule = [
    ValidUtil.required(this.$lang("validation.required")),
  ];
  readonly responseFormatItems = Object.entries(ParsingRuleResponseFormat).map(
    ([key, value]) => ({
      text: this.$lang(
        `appAdmin.networksManagement.parsingRules.${key.toLowerCase()}`
      ),
      value,
    })
  );
  valid = true;
  localValue: ParsingRuleRequestModel = new ParsingRuleRequestModel();

  get networkIntegrationId(): number {
    return Number(this.$route.params.networkIntegrationId);
  }

  get isSavingInProgress(): boolean {
    return this.$store.state.networksManagementStore
      .savingParsingRulesInProgress;
  }

  get isLoading(): boolean {
    return this.$store.state.networksManagementStore.parsingRulesIsLoading;
  }

  get parsingRules(): ParsingRuleResponseModel | null {
    return this.$store.state.networksManagementStore.parsingRules;
  }

  get isNodesVisible(): boolean {
    return (
      !!this.localValue.responseFormatRule &&
      [
        ParsingRuleResponseFormat.JSON,
        ParsingRuleResponseFormat.JSON_MAP,
        ParsingRuleResponseFormat.JSON_MATRIX,
        ParsingRuleResponseFormat.JSON_WITH_DYNAMIC_KEYS,
        ParsingRuleResponseFormat.JSON_WITH_UNPIVOT_CHILDREN_OBJECT,
        ParsingRuleResponseFormat.GET_GEO_API_CURRENCY_JSON,
        ParsingRuleResponseFormat.AGGREGATED_JSON,
      ].includes(this.localValue.responseFormatRule.responseFormat)
    );
  }

  get isIncludeChildrenNodesVisible(): boolean {
    return (
      !!this.localValue.responseFormatRule &&
      [
        ParsingRuleResponseFormat.JSON,
        ParsingRuleResponseFormat.JSON_MAP,
        ParsingRuleResponseFormat.JSON_MATRIX,
        ParsingRuleResponseFormat.JSON_WITH_DYNAMIC_KEYS,
        ParsingRuleResponseFormat.JSON_WITH_UNPIVOT_CHILDREN_OBJECT,
        ParsingRuleResponseFormat.GET_GEO_API_CURRENCY_JSON,
      ].includes(this.localValue.responseFormatRule.responseFormat)
    );
  }

  get isCsv(): boolean {
    return (
      this.localValue.responseFormatRule?.responseFormat ===
      ParsingRuleResponseFormat.CSV
    );
  }

  get isChartboost(): boolean {
    return (
      this.localValue.responseFormatRule?.responseFormat ===
      ParsingRuleResponseFormat.CHARTBOOST
    );
  }

  get isChildrenNodeVisible(): boolean {
    return (
      this.localValue.responseFormatRule?.responseFormat ===
      ParsingRuleResponseFormat.JSON_WITH_UNPIVOT_CHILDREN_OBJECT
    );
  }

  get isExcludeNodesVisible(): boolean {
    return (
      this.localValue.responseFormatRule?.responseFormat ===
      ParsingRuleResponseFormat.AGGREGATED_JSON
    );
  }

  get requiredNodesRule(): Array<any> {
    return this.localValue.responseFormatRule?.responseFormat ===
      ParsingRuleResponseFormat.AGGREGATED_JSON
      ? [ValidUtil.requiredArray(this.$lang("validation.required"))]
      : [];
  }

  @Watch("parsingRules", { deep: true, immediate: true })
  private watchParsingRules(value: ParsingRuleResponseModel | null) {
    if (value) {
      this.localValue = ParsingRuleRequestModel.ofSource(value);
    } else {
      this.localValue = new ParsingRuleRequestModel();
    }
  }

  @Watch("networkIntegrationId", { immediate: true })
  private watchNetworkIntegrationId(value: number) {
    this.localValue.networkIntegrationId = value;
  }

  handleSaveParsingRule() {
    if (this.parsingRules) {
      this.$store.dispatch("updateParsingRules", {
        id: this.parsingRules.id,
        payload: this.localValue,
      });
    } else {
      this.$store.dispatch("createParsingRules", this.localValue);
    }
  }
}
