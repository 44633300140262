




































import { Component, Vue, Prop } from "vue-property-decorator";

import { ObjectRuleModel } from "@/settings/models/networks-management/ParsingRuleRequestModel";
import ValidUtil from "@/shared/utils/ValidUtil";

@Component
export default class ParsingRuleObjectForm extends Vue {
  @Prop() value!: ObjectRuleModel;

  readonly requiredRule = [
    ValidUtil.required(this.$lang("validation.required")),
  ];

  get localValue(): ObjectRuleModel {
    return this.value;
  }

  set localValue(value: ObjectRuleModel) {
    this.$emit("input", value);
  }
}
