
























































import { Component, Vue, Prop, Ref } from "vue-property-decorator";

import { VueForm } from "@/shared/types/ExtendedVueType";

@Component
export default class NetworkSidebarForm extends Vue {
  @Prop({ default: false }) value!: boolean;
  @Prop({ default: "" }) title!: string;
  @Prop({ default: false }) savingInProgress!: boolean;
  @Prop({ default: false }) hasCustomFooter!: boolean;

  @Ref("form") readonly form!: VueForm;

  valid = true;

  get dark(): boolean {
    return this.$vuetify.theme.dark;
  }

  get localValue(): boolean {
    return this.value;
  }

  set localValue(value: boolean) {
    this.$emit("input", value);
  }

  handleClose() {
    this.localValue = false;
    this.form.resetValidation();
  }

  emitSave() {
    if (!this.form.validate()) {
      return;
    }

    this.$emit("save", () => this.handleClose());
  }
}
