













































import { Component, Prop, Vue } from "vue-property-decorator";

import NetworkSidebarForm from "./NetworkSidebarForm.vue";

@Component({
  components: {
    NetworkSidebarForm,
  },
})
export default class EntityCard extends Vue {
  @Prop({ default: false }) visibleForm!: boolean;
  @Prop({ default: "" }) formTitle!: string;
  @Prop({ default: "" }) title!: string;
  @Prop({ default: "" }) subtitle!: string;
  @Prop({ default: "mdi-plus" }) actionIcon!: string;
  @Prop({ default: false }) changeable!: boolean;
  @Prop({ default: false }) savingInProgress!: boolean;
  @Prop({ default: false }) hasCustomFormFooter!: boolean;

  get visible(): boolean {
    return this.visibleForm;
  }

  set visible(value: boolean) {
    this.$emit("change:visible", value);
  }

  emitSave(closeDialog: () => void) {
    this.$emit("save", closeDialog);
  }
}
