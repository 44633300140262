
























































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { cloneDeep } from "lodash";

import NetworkSidebarForm from "../NetworkSidebarForm.vue";
import ParsingRulesTableForm from "./ParsingRulesTableForm.vue";
import NetworkIntegrationResponseModel from "@/settings/models/networks-management/NetworkIntegrationResponseModel";
import {
  RuleFieldKey,
  RuleType,
} from "@/settings/models/networks-management/ParsingRuleResponseModel";
import { RuleModel } from "@/settings/models/networks-management/ParsingRuleRequestModel";
import ValidUtil from "@/shared/utils/ValidUtil";

@Component({
  components: {
    NetworkSidebarForm,
    ParsingRulesTableForm,
  },
})
export default class ParsingRulesTable extends Vue {
  @Prop() value!: Array<RuleModel>;
  @Prop() networkIntegration!: NetworkIntegrationResponseModel;

  readonly headers = [
    {
      text: this.$lang("appAdmin.networksManagement.parsingRules.fieldKey"),
      align: "start",
      value: "fieldKey",
    },
    {
      text: this.$lang("appAdmin.networksManagement.parsingRules.type"),
      align: "start",
      value: "type",
    },
    {
      text: this.$lang("appAdmin.networksManagement.value"),
      align: "start",
      value: "value",
    },
    {
      text: this.$lang("appAdmin.networksManagement.actions"),
      align: "end",
      value: "actions",
    },
  ];
  visible = false;
  editingItem: RuleModel | null = null;
  localValue: RuleModel = {
    fieldKey: RuleFieldKey.DATE,
    type: RuleType.CONSTANT,
  };

  get rules(): Array<RuleModel> {
    return this.value;
  }

  get requiredRule() {
    return this.localValue.type !== RuleType.JSON
      ? [ValidUtil.required(this.$lang("validation.required"))]
      : [];
  }

  get sidebarFormTitle(): string {
    return this.$lang(
      `appAdmin.networksManagement.parsingRules.${
        this.editingItem ? "editRuleTitle" : "newRuleTitle"
      }`
    );
  }

  @Watch("visible")
  watchVisible(value: boolean) {
    if (!value) {
      this.resetLocalData();
    }
  }

  handleClickByTableRow(item: RuleModel) {
    this.localValue = cloneDeep(item);
    this.editingItem = item;
    this.visible = true;
  }

  getItemValue(item: RuleModel): string {
    return item.subRule?.value ?? item.value ?? "";
  }

  emitDeletingItem(item: RuleModel) {
    this.$emit(
      "input",
      this.rules.filter((rule) => rule.fieldKey !== item.fieldKey)
    );
  }

  emitSave(closeDialog: () => void) {
    if (this.editingItem) {
      const editingItemIndex: number = this.rules.findIndex(
        ({ fieldKey }) => fieldKey === this.editingItem?.fieldKey
      );

      if (editingItemIndex !== -1) {
        this.rules.splice(editingItemIndex, 1, this.localValue);
      }

      this.$emit("input", this.rules);
    } else {
      this.$emit("input", [...this.rules, this.localValue]);
    }

    closeDialog();
    this.resetLocalData();
  }

  resetLocalData() {
    this.localValue = {
      fieldKey: RuleFieldKey.DATE,
      type: RuleType.CONSTANT,
    };
    this.editingItem = null;
  }
}
