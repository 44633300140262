









import { Component, Prop, Vue } from "vue-property-decorator";

import EntityCard from "@/settings/components/networks-management/EntityCard.vue";
import ParsingRulesForm from "@/settings/components/networks-management/parsing-rules/ParsingRulesForm.vue";
import NetworkIntegrationResponseModel from "@/settings/models/networks-management/NetworkIntegrationResponseModel";

@Component({
  components: {
    EntityCard,
    ParsingRulesForm,
  },
})
export default class ParsingRulesView extends Vue {
  @Prop() networkIntegration!: NetworkIntegrationResponseModel;

  created() {
    this.$store.dispatch(
      "loadParsingRulesByNetworkIntegrationId",
      this.networkIntegration.id
    );
  }
}
