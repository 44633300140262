













































import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import ParsingRuleDateForm from "./ParsingRuleDateForm.vue";
import ParsingRuleObjectForm from "./ParsingRuleObjectForm.vue";
import { RuleModel } from "@/settings/models/networks-management/ParsingRuleRequestModel";
import NetworkIntegrationResponseModel from "@/settings/models/networks-management/NetworkIntegrationResponseModel";
import {
  RuleFieldKey,
  RuleType,
} from "@/settings/models/networks-management/ParsingRuleResponseModel";
import ValidUtil from "@/shared/utils/ValidUtil";

@Component({
  components: {
    ParsingRuleDateForm,
    ParsingRuleObjectForm,
  },
})
export default class ParsingRulesTableForm extends Vue {
  @Prop() networkIntegration!: NetworkIntegrationResponseModel;
  @Prop() value!: RuleModel;
  @Prop() editingItem!: RuleModel;
  @Prop() rules!: Array<RuleModel>;

  readonly typeItems = Object.entries(RuleType).map(([key, value]) => ({
    text: key,
    value,
  }));

  get localValue(): RuleModel {
    return this.value;
  }

  set localValue(value: RuleModel) {
    this.$emit("input", value);
  }

  get fieldKeyItems(): Array<string> {
    return this.networkIntegration.networkFieldKeys.filter(
      (ruleFieldKey: RuleFieldKey) =>
        this.rules.every(
          ({ fieldKey }) =>
            fieldKey === this.editingItem?.fieldKey || fieldKey !== ruleFieldKey
        )
    );
  }

  get isDateTypeSelected(): boolean {
    return this.localValue?.type === RuleType.DATE;
  }

  get isObjectTypeSelected(): boolean {
    return this.localValue?.type === RuleType.OBJECT;
  }

  get requiredRule(): Array<any> {
    return this.localValue.type !== RuleType.JSON
      ? [ValidUtil.required(this.$lang("validation.required"))]
      : [];
  }

  @Watch("localValue.type")
  private watchLocalValueType() {
    if (this.isDateTypeSelected) {
      this.localValue = {
        fieldKey: this.localValue.fieldKey,
        type: this.localValue.type,
        format: this.localValue.format ?? "",
        subRule: {
          type: this.localValue.subRule?.type ?? RuleType.CONSTANT,
          value: this.localValue.subRule?.value ?? "",
        },
      };
    } else if (this.isObjectTypeSelected) {
      this.localValue = {
        fieldKey: this.localValue.fieldKey,
        type: this.localValue.type,
        key: this.localValue.key ?? "",
        keyValue: this.localValue.keyValue ?? "",
        node: this.localValue.node ?? "",
        value: this.localValue.value ?? "",
      };
    } else {
      this.localValue = {
        fieldKey: this.localValue.fieldKey,
        type: this.localValue.type,
        value: this.localValue.value ?? "",
      };
    }
  }
}
